import { VBtn } from 'vuetify/lib/components/VBtn';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VExpansionPanels,{staticClass:"ExpansionBordered mt-6 ExpansionComponent"},[_c(VExpansionPanel,[_c(VExpansionPanelHeader,{staticClass:"ExpansionTitle",attrs:{"expand-icon":""},on:{"click":function($event){return _vm.changeStateExpansive()}},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c(VIcon,{staticClass:"iconExpand"},[_vm._v(" "+_vm._s(_vm.iconExpansive)+" ")])]},proxy:true}])},[_vm._v(" E-mail "),_c('div',{staticClass:"ExpansionState HideOnMovil"},[_vm._v(" "+_vm._s(_vm.stateExpansiveMessage)+" ")])]),_c(VExpansionPanelContent,[_c('div',{staticClass:"ExpandContent d-flex justify-center"},[_c('div',{staticClass:"ExpandEmailCont pa-5"},[_c('div',{staticClass:"emailContainer"},[_c('div',{staticClass:"emailCont"},[_c('EmailHeader',{attrs:{"header":_vm.headerEmail,"account":_vm.nameReference,"reference":_vm.nameReference}}),_c('CcParticipants',{ref:"ccClose",attrs:{"ccParticipants":_vm.ccParticipants}}),_c('CcoParticipants',{ref:"ccoClose",attrs:{"ccoParticipants":_vm.ccoParticipants}}),_c('div',{staticClass:"ExpansionLineTop mt-4"}),_c('EmailEditor',{ref:"emailEditor",attrs:{"htmlEmail":_vm.htmlEmail}})],1),_c('div',{staticClass:"sendCont"},[_c(VBtn,{staticClass:"button",attrs:{"rounded":"","depressed":"","color":"#003D6D"},on:{"click":function($event){return _vm.closeAccountAndSendEmail()}}},[_vm._v(" Send ")])],1)])])])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }